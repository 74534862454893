var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "gov-table",
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "column" } }, [
                    _vm._v("User")
                  ]),
                  _c("gov-table-header", { attrs: { scope: "column" } }, [
                    _vm._v("To")
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("First name")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.user.first_name))])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Last name")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.user.last_name))])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Email")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.user.email))])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Phone")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.user.phone))])
                ],
                1
              ),
              _vm.organisation.id
                ? _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Organisation")]
                      ),
                      _c("gov-table-cell", [
                        _vm._v(_vm._s(_vm.organisation.name))
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      ),
      _c("gov-section-break", { attrs: { size: "m" } }),
      !_vm.organisation.id
        ? _c(
            "gov-table",
            [
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "gov-table-row",
                    [
                      _c("gov-table-header", { attrs: { scope: "column" } }, [
                        _vm._v("Organisation")
                      ]),
                      _c("gov-table-header", { attrs: { scope: "column" } }, [
                        _vm._v("To")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Name")]
                      ),
                      _c("gov-table-cell", [
                        _vm._v(_vm._s(_vm.organisation.name))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Slug")]
                      ),
                      _c("gov-table-cell", [
                        _vm._v(_vm._s(_vm.organisation.slug))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Description")]
                      ),
                      _c("gov-table-cell", [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.toHtml(_vm.organisation.description)
                            )
                          }
                        })
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("URL")]
                      ),
                      _c("gov-table-cell", [
                        _vm._v(_vm._s(_vm.organisation.url))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Email")]
                      ),
                      _c("gov-table-cell", [
                        _vm._v(_vm._s(_vm.organisation.email || "-"))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Phone")]
                      ),
                      _c("gov-table-cell", [
                        _vm._v(_vm._s(_vm.organisation.phone || "-"))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        : _vm._e(),
      _c("gov-section-break", { attrs: { size: "m" } }),
      _vm.service
        ? _c(
            "gov-table",
            [
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "gov-table-row",
                    [
                      _c("gov-table-header", { attrs: { scope: "column" } }, [
                        _vm._v("Service")
                      ]),
                      _c("gov-table-header", { attrs: { scope: "column" } }, [
                        _vm._v("To")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Type")]
                      ),
                      _c("gov-table-cell", { attrs: { break: "" } }, [
                        _vm._v(_vm._s(_vm._f("ucfirst")(_vm.service.type)))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("URL")]
                      ),
                      _c("gov-table-cell", { attrs: { break: "" } }, [
                        _vm._v(_vm._s(_vm.service.url))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Name")]
                      ),
                      _c("gov-table-cell", [_vm._v(_vm._s(_vm.service.name))])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Slug")]
                      ),
                      _c("gov-table-cell", [_vm._v(_vm._s(_vm.service.slug))])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Intro")]
                      ),
                      _c("gov-table-cell", [_vm._v(_vm._s(_vm.service.intro))])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Is free")]
                      ),
                      _c("gov-table-cell", [
                        _vm._v(_vm._s(_vm._f("isFree")(_vm.service.is_free)))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Criteria")]
                      ),
                      _c(
                        "gov-table-cell",
                        [
                          _c("gov-list", [
                            _c("li", [
                              _c(
                                "span",
                                { staticClass: "govuk-!-font-weight-bold" },
                                [_vm._v("Age group:")]
                              ),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.service.criteria.age_group || "N/A"
                                  ) +
                                  "\n            "
                              )
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticClass: "govuk-!-font-weight-bold" },
                                [_vm._v("Disability:")]
                              ),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.service.criteria.disability || "N/A"
                                  ) +
                                  "\n            "
                              )
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticClass: "govuk-!-font-weight-bold" },
                                [_vm._v("Employment:")]
                              ),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.service.criteria.employment || "N/A"
                                  ) +
                                  "\n            "
                              )
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticClass: "govuk-!-font-weight-bold" },
                                [_vm._v("Gender:")]
                              ),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.service.criteria.gender || "N/A") +
                                  "\n            "
                              )
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticClass: "govuk-!-font-weight-bold" },
                                [_vm._v("Housing:")]
                              ),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.service.criteria.housing || "N/A"
                                  ) +
                                  "\n            "
                              )
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticClass: "govuk-!-font-weight-bold" },
                                [_vm._v("Income:")]
                              ),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.service.criteria.income || "N/A") +
                                  "\n            "
                              )
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticClass: "govuk-!-font-weight-bold" },
                                [_vm._v("Language:")]
                              ),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.service.criteria.language || "N/A"
                                  ) +
                                  "\n            "
                              )
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticClass: "govuk-!-font-weight-bold" },
                                [_vm._v("Other:")]
                              ),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.service.criteria.other || "N/A") +
                                  "\n            "
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Fees URL")]
                      ),
                      _c("gov-table-cell", { attrs: { break: "" } }, [
                        _vm._v(_vm._s(_vm.service.fees_url || "N/A"))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Fees text")]
                      ),
                      _c("gov-table-cell", [
                        _vm._v(_vm._s(_vm.service.fees_text || "N/A"))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Wait time")]
                      ),
                      _c("gov-table-cell", [
                        _vm._v(_vm._s(_vm.service.wait_time || "N/A"))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Description")]
                      ),
                      _c("gov-table-cell", {
                        domProps: {
                          innerHTML: _vm._s(_vm.toHtml(_vm.service.description))
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Testimonial")]
                      ),
                      _c("gov-table-cell", [
                        _vm._v(_vm._s(_vm.service.testimonial || "N/A"))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Video embed")]
                      ),
                      _c("gov-table-cell", [
                        _vm._v(_vm._s(_vm.service.video_embed || "N/A"))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Contact name")]
                      ),
                      _c("gov-table-cell", [
                        _vm._v(_vm._s(_vm.service.contact_name))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Useful infos")]
                      ),
                      _c(
                        "gov-table-cell",
                        [
                          _vm.service.useful_infos.length > 0
                            ? _c(
                                "gov-list",
                                _vm._l(_vm.service.useful_infos, function(
                                  usefulInfo,
                                  index
                                ) {
                                  return _c(
                                    "li",
                                    { key: "useful_info." + index },
                                    [
                                      _c("gov-heading", [
                                        _vm._v(_vm._s(usefulInfo.title))
                                      ]),
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.toHtml(usefulInfo.description)
                                          )
                                        }
                                      }),
                                      index <
                                      _vm.service.useful_infos.length - 1
                                        ? _c("gov-section-break", {
                                            attrs: { visible: "", size: "l" }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : [_vm._v("None")]
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Offerings")]
                      ),
                      _c(
                        "gov-table-cell",
                        [
                          _vm.service.offerings.length > 0
                            ? _c(
                                "gov-list",
                                { attrs: { bullet: "" } },
                                _vm._l(_vm.service.offerings, function(ref) {
                                  var offering = ref.offering
                                  var order = ref.order
                                  return _c(
                                    "li",
                                    { key: "ServiceOffering::New::" + order },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(offering) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : [_vm._v("None")]
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Contact email")]
                      ),
                      _c("gov-table-cell", [
                        _vm._v(_vm._s(_vm.service.contact_email || "-"))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Contact phone")]
                      ),
                      _c("gov-table-cell", [
                        _vm._v(_vm._s(_vm.service.contact_phone || "-"))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Social medias")]
                      ),
                      _c(
                        "gov-table-cell",
                        { attrs: { break: "" } },
                        [
                          _vm.service.social_medias.length > 0
                            ? _c(
                                "gov-list",
                                _vm._l(_vm.service.social_medias, function(
                                  socialMedia,
                                  index
                                ) {
                                  return _c(
                                    "li",
                                    { key: "social_media." + index },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "govuk-!-font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("socialMediaType")(
                                                  socialMedia.type
                                                )
                                              ) +
                                              ":\n              "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(socialMedia.url) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : [_vm._v("None")]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }